import Image from "next/image";
import notFound from "../public/images/404.webp";

export default function Page({
  recentSearches,
  widget,
}: {
  recentSearches: any;
  widget?: boolean;
}) {
  return (
    <div className="flex w-full items-center justify-center">
      <div
        className={`flex ${widget && "mt-8 h-[600px]"} ${
          !widget &&
          recentSearches?.filter((s) => s.heart).length > 0 &&
          "mt-4 h-[80vh]"
        } ${
          !widget &&
          recentSearches?.filter((s) => s.heart).length === 0 &&
          "mt-20 h-[75vh]"
        } w-3/4 items-center justify-center rounded-lg bg-white`}
      >
        {" "}
        <div className="flex w-full items-center justify-center">
          <div className="w-4/5">
            <Image src={notFound} alt="Page Not Found (404)" />
          </div>
        </div>
      </div>
    </div>
  );
}
